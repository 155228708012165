import { useMetadataAnimalUniverses } from "@igloo-be-omnipartners/hooks";
import { WindowLocation } from "@reach/router";
import {
  Button,
  Dropdown,
  EmptyLayout,
  Fieldset,
  Form,
  IDropdownItem,
  Input,
  LittersSubmit,
  Loader,
  PageTitle,
  RadioGroup,
  useButtonProps,
  useInputProps,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import { uniqBy } from "lodash";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { number, object, string } from "yup";
import { Locale } from "../../../../../__generated__/globalTypes";
import { Layout } from "../../components/Layout";
import { useLitterKits } from "../../lib/hooks/useLitterKits";
import { useLitter, useLitterUpdateKit } from "../../lib/hooks/useLitters";
import { useLocationContext } from "../../lib/LocationProvider";
import { useHasMounted } from "../../lib/utils";
import { withPage } from "../../lib/withPage";
import Error404 from "../404";

const petTypes = [
  {
    label: "Chien",
    value: "DOG",
  },
  {
    label: "Chat",
    value: "CAT",
  },
];

const LittersKits = ({ location }: { location: WindowLocation<any> }) => {
  const {
    query: { litter: litterId, next, backUrl },
    navigate,
  } = useLocationContext();
  const { litter, loading: litterLoading } = useLitter(litterId as string);
  const { litterUpdateKit } = useLitterUpdateKit({ litterId });
  const { litterKits: baseLitterKits } = useLitterKits();
  const litterKits = baseLitterKits.filter(
    (l) => l.petType === litter?.petType,
  );

  const isEmptyLoyout = !!(backUrl && useHasMounted());
  const LayoutComponent = isEmptyLoyout ? EmptyLayout : undefined;
  const layoutProps = { LayoutComponent };

  const { items: universes, loading } = useMetadataAnimalUniverses({
    lang: Locale.fr,
    type: (litter && litter.petType.toLowerCase()) || "",
  });
  const title = (
    <PageTitle subTitle="Contenu de votre commande">
      Commander des kits
    </PageTitle>
  );

  if (litterLoading) {
    return (
      <Layout {...layoutProps}>
        <Wrapper>
          {title}
          <Loader />
        </Wrapper>
      </Layout>
    );
  }
  if (!litter) {
    return (
      <Error404
        location={location}
        layoutProps={layoutProps}
        hideHomeButton={isEmptyLoyout}
      />
    );
  }

  const inferedUniverseId =
    litter.petType === "CAT" && universes.length
      ? universes[0].value
      : universes.find(
          (universe) =>
            litter.breed && universe.value === litter.breed.universe,
        )?.value;

  const breedSuggestions = litterKits
    .map((kit) => {
      if (kit.breeds && litter.breed && kit.breeds.includes(litter.breed.id)) {
        return {
          label: kit.label,
          value: kit.id,
        };
      }
      return;
    })
    .filter(Boolean) as IDropdownItem<string>[];

  const universeSuggestions = litterKits
    .map((kit) => {
      if (
        kit.universes &&
        inferedUniverseId &&
        kit.universes.includes(inferedUniverseId)
      ) {
        return {
          label: kit.label,
          value: kit.id,
        };
      }
      return;
    })
    .filter(Boolean) as IDropdownItem<string>[];

  const kitByUniverse = litterKits
    .filter((kit) => kit.mode === "universe")
    .map((kit) => ({
      label: kit.label,
      value: kit.id,
    }));

  const kitByBreed = litterKits
    .filter((kit) => kit.mode === "breed")
    .map((kit) => ({
      label: kit.label,
      value: kit.id,
    }));

  const kitSelectorChoices = [
    {
      label: "Suggéré pour vous",
      value: "suggestion",
      items: uniqBy([...breedSuggestions, ...universeSuggestions], "value"),
    },
    {
      label: "Par taille",
      value: "size",
      items: kitByUniverse,
    },
    {
      label: "Par race",
      value: "breeds",
      items: kitByBreed,
    },
  ];

  return (
    <Layout {...layoutProps}>
      <Wrapper>
        <Form
          onSubmit={async ({ litterKit, quantity }) => {
            if (litter) {
              const res = await litterUpdateKit({
                litterKit,
                quantity:
                  typeof quantity === "string"
                    ? parseInt(quantity, 10)
                    : quantity,
              });
              if (res && res.result && res.result.id) {
                if (next === "ad") {
                  navigate("/litters/ad", {
                    litter: res.result.id,
                    kitCreated: true,
                  });
                } else {
                  navigate("/litters/success", {
                    litter: res.result.id,
                    kitCreated: true,
                    backUrl,
                  });
                }
              }
            }
          }}
          fieldLabels={{
            litterKit: "Kit",
            quantity: "Quantité",
          }}
          initialValues={{
            litterKit:
              (universeSuggestions[0] && universeSuggestions[0].value) ||
              (breedSuggestions[0] && breedSuggestions[0].value) ||
              (kitByUniverse[0] && kitByUniverse[0].value) ||
              (kitByBreed[0] && kitByBreed[0].value),
            quantity: (litter && litter.totalCount) || 0,
          }}
          schema={object().shape({
            litterKit: string().required(),
            quantity: number()
              .min(1)
              .max((litter && litter.totalCount) || 0)
              .required(),
          })}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {title}
              <Row center="sm">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <RadioGroup
                        items={petTypes}
                        onChange={() => null}
                        value={(litter && litter.petType) || ""}
                        disabled={true}
                        type="radio"
                      >
                        Animal
                      </RadioGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Fieldset offset={15}>
                        <Dropdown
                          {...useInputProps({ name: "litterKit" })}
                          fullWidth
                          items={kitSelectorChoices}
                          disabled={loading}
                        />
                      </Fieldset>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Fieldset offset={15}>
                        <Input
                          {...useInputProps({ name: "quantity" })}
                          fullWidth
                          disabled={loading}
                          type="number"
                          inputProps={{
                            min: 1,
                            max: (litter && litter.totalCount) || 0,
                          }}
                        />
                      </Fieldset>
                    </Col>
                  </Row>
                  <LittersSubmit backLink={!backUrl ? "/litters" : undefined}>
                    <Button type="submit" {...useButtonProps()}>
                      Continuer
                    </Button>
                  </LittersSubmit>
                </Col>
              </Row>
            </form>
          )}
        />
      </Wrapper>
    </Layout>
  );
};

export default withPage(LittersKits, {
  title: "Portées",
});
