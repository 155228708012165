import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import { LitterKits, LitterKitsVariables } from "./__generated__/LitterKits";

const LitterKitsQuery = gql`
  query LitterKits(
    $token: String!
    $locale: Locale!
    $page: Float
    $limit: Float
  ) {
    litterKits(token: $token, locale: $locale, page: $page, limit: $limit) {
      pageInfo {
        hasNextPage
        count
        page
        limit
      }
      result {
        id
        active
        reference
        label
        subLabel
        image
        priority
        petType
        mode
        universes
        breeds
      }
    }
  }
`;

export const useLitterKits = ({
  page,
  limit,
}: {
  page?: number;
  limit?: number;
} = {}) => {
  const token = useUserToken();
  const res = useQuery<LitterKits, LitterKitsVariables>(LitterKitsQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale.fr,
      limit: limit || 50,
      page: page || 1,
    },
  });

  const pageInfo = (res.data &&
    res.data.litterKits &&
    res.data.litterKits.pageInfo) || {
    page: 1,
    hasNextPage: false,
  };

  return {
    ...res,
    litterKits:
      (res.data &&
        res.data.litterKits &&
        res.data.litterKits.result &&
        res.data.litterKits.result.filter(litterKit => litterKit.active)) ||
      [],
    pageInfo,
    onLoadMore: async () => {
      if (!pageInfo.hasNextPage || res.loading) {
        return Promise.resolve();
      }

      return res.fetchMore({
        variables: {
          page: pageInfo.page + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            litterKits: {
              ...prev.litterKits,
              result: [
                ...prev.litterKits.result,
                ...fetchMoreResult.litterKits.result,
              ],
              pageInfo: fetchMoreResult.litterKits.pageInfo,
            },
          };
        },
      });
    },
  };
};
